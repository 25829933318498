/** @jsx jsx */
import { jsx } from 'theme-ui';

import React from 'react';
import { Flex, Box, Grid, Card, Heading, Text, Image } from 'theme-ui';
import { Link, graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { Helmet } from 'react-helmet';
import { Trans, t } from '@lingui/macro';

import Breadcrumbs from '../../../plugins/storefront/src/components/Breadcrumbs';

const VideoGuidePage = (props) => {
  const { storeName } = props.data.site.siteMetadata.gatsbyStorefrontConfig;
  return (
    <>
      <Helmet
        title={t({
          id: `VideoGuide.Title`,
          message: `Video guide`,
        })}
        titleTemplate={t`%s — ${storeName}`}
        defer={false}
      >
        <meta
          name="description"
          content={t({
            id: `VideoGuide.MetaDescription`,
            message: `Video guide how to install Tape-ins Monotapes`,
          })}
        />
      </Helmet>
      <Flex
        my={3}
        py={[2, 3, 4]}
        sx={{
          width: '100%',
          bg: '#f4f1eb',
          justifyContent: 'center',
        }}
      >
        <Box mx={3}>
          <Breadcrumbs
            collectionTitle={t({
              id: `VideoGuide.BreadcrumbsParentTitle`,
              message: `Guides`,
            })}
            collectionPath={'/guides'}
            productTitle={t({
              id: `VideoGuide.BreadcrumbsTitle`,
              message: `Video guide`,
            })}
            separator="/"
          />
        </Box>
      </Flex>

      <Box
        mx="auto"
        px={[3, 2]}
        mt={[1, 4]}
        mb={4}
        sx={{
          width: '100%',
          maxWidth: 1300,
        }}
      >
        <Heading as="h1" sx={{ fontSize: [4, 5] }} mb={[3, 4]}>
          <Trans id="VideoGuide.Header">Video guide</Trans>
        </Heading>

        <Grid columns={[1, 2]}>
          <Flex sx={{ flexDirection: 'column' }}>
            <Heading as="h2" sx={{ fontSize: [3, 4] }} mb={[2, 3]}>
              <Trans id="VideoGuide.InstallHeader">
                How to install monotapes
              </Trans>
            </Heading>
            <iframe
              sx={{ width: '100%', height: 315 }}
              src="https://www.youtube.com/embed/tlNGiV1c-0M"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </Flex>

          <Flex sx={{ flexDirection: 'column' }}>
            <Heading as="h2" sx={{ fontSize: [3, 4] }} mb={[2, 3]}>
              <Trans id="VideoGuide.RemoveHeader">How remove monotapes</Trans>
            </Heading>
            <iframe
              sx={{ width: '100%', height: 315 }}
              src="https://www.youtube.com/embed/FXcwq62BC9M"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </Flex>

          <Flex sx={{ flexDirection: 'column' }}>
            <Heading as="h2" sx={{ fontSize: [3, 4] }} mb={[2, 3]}>
              <Trans id="VideoGuide.TapeReplacementHeader">
                Tape replacement process
              </Trans>
            </Heading>
            <iframe
              sx={{ width: '100%', height: 315 }}
              src="https://www.youtube.com/embed/MyuZaFwaozo"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </Flex>
        </Grid>
      </Box>
    </>
  );
};

export const VideoGuidePageQuery = graphql`
  query {
    site {
      siteMetadata {
        gatsbyStorefrontConfig {
          storeName
        }
      }
    }
  }
`;

export default VideoGuidePage;
